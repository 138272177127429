<template>
  <div
    :id="id"
    class="paragraph-cta mt-section"
    :class="{
      'mt-section': !parentType,
      '!mt-0 not-last:mb-7': parentType,
    }"
  >
    <div
      v-if="image"
      v-blokkli-droppable:field_image
      class="size-aware-full-image my-6"
    >
      <MediaImage
        v-bind="image"
        :image-style="defaultImageStyle"
        :hide-caption="!options.showCaption"
      />
    </div>

    <div class="container grid gap-medium">
      <component
        :is="headerSize"
        v-if="title"
        class="max-w-screen-md text-balance"
        :class="headerSize"
      >
        {{ title }}
      </component>

      <div v-if="text">
        <ContainerGrid>
          <div class="col-span-full lg:col-span-10 xl:col-span-8">
            <div
              v-blokkli-editable:field_text
              class="ck-content hyphens-auto lg:hyphens-none"
              v-html="text"
            />
          </div>
        </ContainerGrid>
      </div>
    </div>

    <div class="container mt-6 lg:mt-9">
      <DrupalLink
        v-slot="{ isExternal }"
        :to="link?.uri?.path"
        class="button ml-[-3px] is-link"
      >
        {{ label }}
        <span v-if="isExternal" class="sr-only">
          {{
            $texts(
              'accessibility.link_external',
              'Externer Link, wird in einem anderen Tab oder Fenster geöffnet.',
            )
          }}
        </span>
      </DrupalLink>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { slugify } from '#vuepal/helpers/url'
import type { ParagraphCallToActionFragment } from '#graphql-operations'

const { breakpoints, rokkaMax } = useTailwind()

const props = defineProps<{
  title?: string
  anchorTitle?: string
  text?: string
  image?: ParagraphCallToActionFragment['image']
  label: ParagraphCallToActionFragment['label']
  link: ParagraphCallToActionFragment['link']
}>()

const { options, parentType } = defineBlokkli({
  bundle: 'call_to_action',
  globalOptions: ['showCaption', 'showInAnchor'],
  options: {
    headerSize: {
      type: 'radios',
      label: 'Titelgrösse',
      default: 'h2',
      displayAs: 'icons',
      options: {
        h2: { label: 'H2', icon: 'icon-blokkli-option-h2' },
        h3: { label: 'H3', icon: 'icon-blokkli-option-h3' },
      },
    },
  },
})

const id = computed(() => slugify(props.anchorTitle || props.title))

const headerSize = computed(() => {
  if (options.value.headerSize === 'h2') {
    return 'h2'
  }
  return 'h3'
})

const defaultImageStyle = defineImageStyle({
  type: 'sizes',
  sizes: {
    xs: breakpoints.sm,
    sm: breakpoints.md,
    md: breakpoints.lg,
    lg: breakpoints.xl,
    xl: breakpoints.xxl,
    xxl: rokkaMax,
  },
})
</script>
