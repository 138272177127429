<template>
  <div class="logo-museum-aniversary">
    <span class="sr-only">
      <span>Museum</span><br />
      <span>für Gestaltung</span><br />
      <span>Zürich</span>
    </span>
  </div>
</template>

<style lang="postcss">
.logo-museum-aniversary {
  .logo-museum--negative & {
    filter: invert(1);
  }

  display: block;
  height: 42px;
  aspect-ratio: 114.11 / 42.72;

  @screen md {
    height: 53px;
  }

  background-image: url('./MuseumAniversary.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
</style>
