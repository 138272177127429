import type { Theme } from '#blokkli/types/theme'
import type { ModuleOptionsSettings } from '#blokkli/types/generatedModuleTypes'

export const hasCustomTheme = false
export const themes: Record<string, Theme> = {
  "fire": {
    "accent": {
      "50": [
        254,
        247,
        238
      ],
      "100": [
        254,
        237,
        214
      ],
      "200": [
        251,
        215,
        173
      ],
      "300": [
        249,
        186,
        120
      ],
      "400": [
        245,
        147,
        66
      ],
      "500": [
        242,
        116,
        29
      ],
      "600": [
        228,
        91,
        19
      ],
      "700": [
        188,
        67,
        18
      ],
      "800": [
        150,
        54,
        22
      ],
      "900": [
        121,
        47,
        21
      ],
      "950": [
        65,
        21,
        9
      ]
    },
    "mono": {
      "50": [
        250,
        250,
        249
      ],
      "100": [
        245,
        245,
        244
      ],
      "200": [
        231,
        229,
        228
      ],
      "300": [
        214,
        211,
        209
      ],
      "400": [
        168,
        162,
        158
      ],
      "500": [
        120,
        113,
        108
      ],
      "600": [
        87,
        83,
        78
      ],
      "700": [
        68,
        64,
        60
      ],
      "800": [
        41,
        37,
        36
      ],
      "900": [
        28,
        25,
        23
      ],
      "950": [
        12,
        10,
        9
      ]
    },
    "teal": {
      "light": [
        217,
        239,
        242
      ],
      "normal": [
        82,
        179,
        188
      ],
      "dark": [
        3,
        26,
        28
      ]
    },
    "yellow": {
      "light": [
        242,
        232,
        202
      ],
      "normal": [
        238,
        192,
        59
      ],
      "dark": [
        79,
        64,
        17
      ]
    },
    "red": {
      "light": [
        254,
        242,
        242
      ],
      "normal": [
        239,
        68,
        68
      ],
      "dark": [
        127,
        29,
        29
      ]
    },
    "lime": {
      "light": [
        238,
        243,
        237
      ],
      "normal": [
        103,
        148,
        88
      ],
      "dark": [
        56,
        80,
        48
      ]
    }
  },
  "arctic": {
    "accent": {
      "50": [
        237,
        247,
        255
      ],
      "100": [
        215,
        236,
        255
      ],
      "200": [
        183,
        223,
        255
      ],
      "300": [
        134,
        205,
        255
      ],
      "400": [
        76,
        176,
        255
      ],
      "500": [
        35,
        140,
        255
      ],
      "600": [
        12,
        107,
        255
      ],
      "700": [
        5,
        80,
        230
      ],
      "800": [
        12,
        67,
        193
      ],
      "900": [
        16,
        61,
        152
      ],
      "950": [
        15,
        38,
        92
      ]
    },
    "mono": {
      "50": [
        248,
        250,
        252
      ],
      "100": [
        241,
        245,
        249
      ],
      "200": [
        226,
        232,
        240
      ],
      "300": [
        203,
        213,
        225
      ],
      "400": [
        148,
        163,
        184
      ],
      "500": [
        100,
        116,
        139
      ],
      "600": [
        71,
        85,
        105
      ],
      "700": [
        51,
        65,
        85
      ],
      "800": [
        30,
        41,
        59
      ],
      "900": [
        15,
        23,
        42
      ],
      "950": [
        2,
        6,
        23
      ]
    },
    "teal": {
      "light": [
        204,
        251,
        241
      ],
      "normal": [
        45,
        212,
        191
      ],
      "dark": [
        19,
        78,
        74
      ]
    },
    "yellow": {
      "light": [
        254,
        252,
        232
      ],
      "normal": [
        250,
        204,
        21
      ],
      "dark": [
        113,
        63,
        18
      ]
    },
    "red": {
      "light": [
        254,
        242,
        242
      ],
      "normal": [
        239,
        68,
        68
      ],
      "dark": [
        127,
        29,
        29
      ]
    },
    "lime": {
      "light": [
        236,
        252,
        203
      ],
      "normal": [
        101,
        163,
        13
      ],
      "dark": [
        63,
        98,
        18
      ]
    }
  },
  "gruvbox": {
    "accent": {
      "50": [
        251,
        245,
        246
      ],
      "100": [
        248,
        235,
        238
      ],
      "200": [
        242,
        217,
        223
      ],
      "300": [
        230,
        187,
        197
      ],
      "400": [
        211,
        134,
        155
      ],
      "500": [
        198,
        109,
        135
      ],
      "600": [
        175,
        79,
        112
      ],
      "700": [
        146,
        62,
        93
      ],
      "800": [
        123,
        54,
        82
      ],
      "900": [
        123,
        54,
        82
      ],
      "950": [
        58,
        23,
        37
      ]
    },
    "mono": {
      "50": [
        254,
        253,
        251
      ],
      "100": [
        251,
        248,
        239
      ],
      "200": [
        234,
        230,
        220
      ],
      "300": [
        230,
        222,
        209
      ],
      "400": [
        168,
        153,
        132
      ],
      "500": [
        124,
        103,
        100
      ],
      "600": [
        102,
        92,
        84
      ],
      "700": [
        80,
        73,
        69
      ],
      "800": [
        50,
        48,
        39
      ],
      "900": [
        40,
        40,
        40
      ],
      "950": [
        29,
        32,
        33
      ]
    },
    "teal": {
      "light": [
        216,
        243,
        233
      ],
      "normal": [
        131,
        165,
        152
      ],
      "dark": [
        38,
        59,
        51
      ]
    },
    "yellow": {
      "light": [
        251,
        241,
        199
      ],
      "normal": [
        250,
        189,
        47
      ],
      "dark": [
        144,
        106,
        20
      ]
    },
    "red": {
      "light": [
        255,
        218,
        214
      ],
      "normal": [
        251,
        73,
        52
      ],
      "dark": [
        142,
        24,
        11
      ]
    },
    "lime": {
      "light": [
        236,
        236,
        197
      ],
      "normal": [
        184,
        187,
        38
      ],
      "dark": [
        80,
        80,
        12
      ]
    }
  },
  "nuxt": {
    "accent": {
      "50": [
        230,
        255,
        245
      ],
      "100": [
        210,
        255,
        237
      ],
      "200": [
        169,
        255,
        220
      ],
      "300": [
        128,
        255,
        203
      ],
      "400": [
        87,
        255,
        186
      ],
      "500": [
        47,
        255,
        170
      ],
      "600": [
        6,
        255,
        153
      ],
      "700": [
        0,
        220,
        130
      ],
      "800": [
        0,
        164,
        97
      ],
      "900": [
        0,
        108,
        64
      ],
      "950": [
        0,
        80,
        47
      ]
    },
    "mono": {
      "50": [
        248,
        250,
        252
      ],
      "100": [
        249,
        249,
        251
      ],
      "200": [
        238,
        240,
        246
      ],
      "300": [
        231,
        233,
        243
      ],
      "400": [
        197,
        200,
        221
      ],
      "500": [
        95,
        97,
        119
      ],
      "600": [
        56,
        58,
        82
      ],
      "700": [
        48,
        50,
        80
      ],
      "800": [
        14,
        16,
        53
      ],
      "900": [
        7,
        9,
        44
      ],
      "950": [
        0,
        1,
        15
      ]
    },
    "teal": {
      "light": [
        211,
        239,
        248
      ],
      "normal": [
        92,
        174,
        201
      ],
      "dark": [
        21,
        43,
        50
      ]
    },
    "yellow": {
      "light": [
        254,
        252,
        232
      ],
      "normal": [
        233,
        182,
        63
      ],
      "dark": [
        95,
        72,
        17
      ]
    },
    "red": {
      "light": [
        243,
        221,
        216
      ],
      "normal": [
        220,
        79,
        48
      ],
      "dark": [
        163,
        63,
        41
      ]
    },
    "lime": {
      "light": [
        230,
        255,
        245
      ],
      "normal": [
        0,
        164,
        97
      ],
      "dark": [
        0,
        80,
        47
      ]
    }
  }
}
export const theme: Theme = {
  "accent": {
    "50": [
      237,
      247,
      255
    ],
    "100": [
      215,
      236,
      255
    ],
    "200": [
      183,
      223,
      255
    ],
    "300": [
      134,
      205,
      255
    ],
    "400": [
      76,
      176,
      255
    ],
    "500": [
      35,
      140,
      255
    ],
    "600": [
      12,
      107,
      255
    ],
    "700": [
      5,
      80,
      230
    ],
    "800": [
      12,
      67,
      193
    ],
    "900": [
      16,
      61,
      152
    ],
    "950": [
      15,
      38,
      92
    ]
  },
  "mono": {
    "50": [
      248,
      250,
      252
    ],
    "100": [
      241,
      245,
      249
    ],
    "200": [
      226,
      232,
      240
    ],
    "300": [
      203,
      213,
      225
    ],
    "400": [
      148,
      163,
      184
    ],
    "500": [
      100,
      116,
      139
    ],
    "600": [
      71,
      85,
      105
    ],
    "700": [
      51,
      65,
      85
    ],
    "800": [
      30,
      41,
      59
    ],
    "900": [
      15,
      23,
      42
    ],
    "950": [
      2,
      6,
      23
    ]
  },
  "teal": {
    "light": [
      204,
      251,
      241
    ],
    "normal": [
      45,
      212,
      191
    ],
    "dark": [
      19,
      78,
      74
    ]
  },
  "yellow": {
    "light": [
      254,
      252,
      232
    ],
    "normal": [
      250,
      204,
      21
    ],
    "dark": [
      113,
      63,
      18
    ]
  },
  "red": {
    "light": [
      254,
      242,
      242
    ],
    "normal": [
      239,
      68,
      68
    ],
    "dark": [
      127,
      29,
      29
    ]
  },
  "lime": {
    "light": [
      236,
      252,
      203
    ],
    "normal": [
      101,
      163,
      13
    ],
    "dark": [
      63,
      98,
      18
    ]
  }
}

export const settingsOverride: ModuleOptionsSettings = {}

export const blokkliVersion = "1.1.1"

export const storageDefaults: Record<string, string|boolean|string[]> = {"blockFavorites":["text"]}
export const defaultLanguage: string = "de"
export const forceDefaultLanguage: boolean = true
