<template>
  <slot :status-message="statusMessage">
    <div :class="$attrs.class">
      {{ statusMessage || '&nbsp;' }}
    </div>
  </slot>
</template>

<script lang="ts" setup>
/**
 * Loads the opening status of all locations once, client only and displays
 * the opening status for the given location ID as plain text.
 *
 * We do this so that GraphQL responses can be cached (likely) indefinitely.
 *
 * During SSR, the component renders a non-breakable space to reserve a single
 * line of text to prevent layout shift.
 */
import {
  OpeningHoursRelativeStatus,
  type LocationOpenStatusFragment,
} from '#graphql-operations'

const props = defineProps<{
  id?: string
}>()

const { $texts } = useNuxtApp()

/**
 * Maps the open status to a human readable text.
 */
function mapStatus(status: LocationOpenStatusFragment) {
  switch (status.relativeStatus) {
    case OpeningHoursRelativeStatus.OPEN:
      return $texts(
        'location.status_open',
        'Offen, schliesst um @time Uhr',
      ).replace('@time', status.closeTime.formatted)

    case OpeningHoursRelativeStatus.BEFORE_OPEN:
      return $texts(
        'location.status_closed_opens_today',
        'Geschlossen, öffnet um @time Uhr',
      ).replace('@time', status.openTime.formatted)

    case OpeningHoursRelativeStatus.AFTER_CLOSE:
    case OpeningHoursRelativeStatus.CLOSED: {
      let text

      if (status.openTomorrow) {
        text = $texts(
          'location.status_closed_opens_tomorrow',
          'Geschlossen, öffnet morgen um @time Uhr',
        )
      } else {
        text = $texts(
          'location.status_closed_opens_other_day',
          'Geschlossen, öffnet am @date um @time Uhr',
        )
      }

      return text
        .replace('@date', status.openDate.formatted)
        .replace('@time', status.openTime.formatted)
    }
  }
}

// @TODO: Invalidate the cached data in
const { data } = await useAsyncGraphqlQuery('locationStatus', null, {
  transform: function (data) {
    return (data.data.entityQuery.items || []).reduce<
      Record<string, LocationOpenStatusFragment>
    >((acc, v) => {
      if (v && 'id' in v && v.id && v.status) {
        acc[v.id] = v.status
      }
      return acc
    }, {})
  },
  graphqlCaching: {
    client: true,
  },
  server: false,
})

const statusMessage = computed<string | undefined>(() => {
  if (!props.id || !data.value) {
    return
  }

  const status = data.value[props.id]

  if (!status) {
    return
  }

  return mapStatus(status)
})
</script>
