<template>
  <div>
    <ClientOnly>
      <div v-click-away="clickAway" class="relative inline-block">
        <button
          :class="{ 'is-high-contrast': isHighContrast }"
          class="button !py-0"
          :disabled="languageLinks.length === 0"
          @click="langSwitchDropdownOpen = !langSwitchDropdownOpen"
        >
          <span aria-hidden="true" class="uppercase">
            {{ currentLanguage }}
          </span>
          <span class="sr-only">
            {{ $texts('accessibility.current_language', 'Aktuelle Sprache') }}
            {{ currentLanguageName }}
          </span>

          <SpriteSymbol v-if="!langSwitchDropdownOpen" name="caron-down" />
          <SpriteSymbol v-else name="caron-up" />
        </button>

        <ul
          v-if="langSwitchDropdownOpen"
          :aria-label="
            $texts(
              'accessibility.other_available_languages',
              'Andere verfügbare Sprachen',
            )
          "
          class="absolute bottom-0 translate-y-full z-10"
        >
          <li
            v-for="(lang, i) in languageLinks"
            :key="`lang_${i}`"
            class="pt-2"
          >
            <a
              :href="lang.to"
              :class="{ 'is-high-contrast': isHighContrast }"
              class="button"
            >
              <span aria-hidden="true" class="uppercase">
                {{ lang.code }}
              </span>
              <span class="sr-only">
                {{ lang.name }}
              </span>
            </a>
          </li>
        </ul>
      </div>
    </ClientOnly>
  </div>
</template>

<script lang="ts" setup>
import type { ComputedRef } from 'vue'
defineProps<{
  isHighContrast?: boolean
}>()

type LanguageLink = {
  code: string
  active: boolean
  to: string
  name: string
}

const LABELS: Record<string, string> = {
  de: 'Deutsch',
  fr: 'Français',
  it: 'Italiano',
  en: 'English',
}

const languages: ComputedRef<LanguageLink[]> = useLanguageLinks()
const currentLanguage = useCurrentLanguage()
const languageLinks = computed(() => {
  return languages.value
    .filter((l) => l.code !== currentLanguage.value)
    .map((item) => {
      return {
        ...item,
        name: LABELS[item.code],
      }
    })
})

const currentLanguageName = computed(() => {
  return LABELS[currentLanguage.value]
})

const langSwitchDropdownOpen = ref(false)
const clickAway = () => {
  if (langSwitchDropdownOpen.value) {
    langSwitchDropdownOpen.value = false
  }
}
</script>
