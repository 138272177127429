export const translations = {
  "de": {
    "addBlockCommand.appendInField": "«@block» in «@field» anfügen",
    "addBlockCommand.appendRoot": "«@block» anfügen",
    "addBlockCommand.insertInField": "«@block» in «@field» einfügen",
    "addCommentToItem": "Kommentieren",
    "addListInputPlaceholder": "Blöcke durchsuchen",
    "addListItemFavoriteAdd": "Zu Favoriten hinzufügen",
    "addListItemFavoriteRemove": "Aus Favoriten entfernen",
    "addListSidebarTitle": "Block hinzufügen",
    "addListTourText": "<p>Hier sehen Sie die Liste der verfügbaren Blöcke. Ziehen Sie einen Block auf die Seite um ihn hinzuzufügen.</p><p>Wenn ein bestehender Block ausgewählt ist sind möglicherweise einige Blöcke ausgegraut. Das bedeutet, dass nur die hervorgehobenen Blöcke im oder nach dem ausgewählten Block hinzugefügt werden können.</p>",
    "all": "Alle",
    "artboard": "Vorschau",
    "artboardOverviewHide": "Übersicht verstecken",
    "artboardOverviewShow": "Übersicht anzeigen",
    "artboardOverviewToggle": "Übersicht umschalten",
    "artboardOverviewTourText": "Zeigt eine schematische Übersicht aller Inhalte an.",
    "artboardResetZoom": "Zoom zurücksetzen",
    "artboardScaleToFit": "An Höhe anpassen",
    "artboardScrollDown": "runterscrollen",
    "artboardScrollOnePageDown": "Eine Seite hochscrollen",
    "artboardScrollOnePageUp": "Eine Seite runterscrollen",
    "artboardScrollToEnd": "Zum Ende scrollen",
    "artboardScrollToTop": "Zum Anfang scrollen",
    "artboardScrollUp": "hochscrollen",
    "artboardToolbarButtonTourText": "Zeigt den aktuellen Zoom-Wert. Klicken Sie auf den Button um den Zoom auf 100% zurückzusetzen.",
    "assistantAddAction": "Mit KI-Assistent hinzufügen",
    "assistantAddActionDescription": "Fügen Sie Inhalte mit dem KI-Assistenten hinzu.",
    "assistantAddResultError": "Fehler beim hinzufügen mit KI.",
    "assistantDialogLead": "Geben Sie ein was der KI-Assistent generieren soll.",
    "assistantDialogSubmit": "Blöcke erstellen",
    "assistantDialogTitle": "Inhalt mit KI generieren",
    "assistantPromptLabel": "Anweisung",
    "assistantPromptPlaceholder": "Schreibe Text zum Thema Steuern in der Schweiz",
    "blockAddListTourText": "Klicken Sie mit der rechten Maustaste auf den Block um ihn zu den Favoriten hinzuzufügen oder zu entfernen. Favoriten werden hervorgehoben und an erster Stelle dargestellt.",
    "blockAddListTourTitle": "Favoriten-Blöcke",
    "blockOption_bkHiddenGlobally_label": "Global verstecken",
    "blockOption_bkVisibleLanguages_label": "Sichtbare Sprachen",
    "cancel": "Abbrechen",
    "clipboard": "Zwischenablage",
    "clipboardCopyShortcutHelp": "Ausgewählte Blöcke kopieren",
    "clipboardEmpty": "Keine Elemente in der Zwischenablage",
    "clipboardExplanation": "<p>Verwenden Sie Ctrl-V auf der Seite um Inhalte einzufügen. Diese werden dann hier angezeigt.</p><p>Verwenden Sie Ctrl-F um bestehende Inhalte zu suchen und in die Zwischenablage einzufügen.</p>",
    "clipboardPastePlaceholder": "Text oder Medien einfügen",
    "clipboardPasteShortcutHelp": "Text, Bild oder kopierte Blöcke einfügen",
    "clipboardTourText": "Ziehen Sie Inhalte, die aus Ihrer Zwischenablage eingefügt wurden, in die Seite hinein, um einen passenden Block zu erstellen.",
    "close": "Schliessen",
    "commandGroup.action": "Aktionen",
    "commandGroup.add": "Hinzufügen",
    "commandGroup.misc": "Diverse",
    "commandGroup.selection": "Auswahl",
    "commandGroup.ui": "Oberfläche",
    "commandPalette.inputPlaceholder": "Befehle durchsuchen...",
    "commandPaletteOpen": "Befehle öffnen",
    "commandPaletteTourText": "Führen Sie Aktionen ganz einfach mit Ihrer Tastatur aus, indem Sie «Befehle» öffnen. Fast alle Funktionen sind auch über «Befehle» ausführbar.",
    "commentAdd": "Kommentar hinzufügen",
    "commentBody": "Kommentar",
    "commentBodyPlaceholder": "Antwort hinzufügen",
    "commentSave": "Kommentar speichern",
    "comments": "Kommentare",
    "commentsMarkAsResolved": "Erledigen",
    "commentsTourText": "Zeigt alle Kommentare auf der aktuellen Seite an.",
    "convertTo": "Konvertieren",
    "deleteButton": "Löschen",
    "deleteError": "Das Element konnte nicht entfernt werden.",
    "droppableEditFormSave": "Speichern",
    "duplicate": "Duplizieren",
    "duplicateError": "Die Elemente konnten nicht dupliziert werden.",
    "edit": "Bearbeiten",
    "editFormBlockAdd": "@label hinzufügen",
    "editFormBlockEdit": "@label bearbeiten",
    "editFormBlockTranslate": "@label übersetzen (@language)",
    "editFormEntityEdit": "\"@label\" bearbeiten",
    "editFormEntityTranslate": "\"@label\" übersetzen (@language)",
    "editIndicatorLabel": "Elemente bearbeiten",
    "editableCommandEdit": "Feld «@name» bearbeiten",
    "entityTitleTourText": "<p>Zeigt den Titel und Status der aktuellen Seite an.</p><p>Klicken Sie auf den Titel um das Bearbeitungsformular anzuzeigen.</p>",
    "entityTitleTourTitle": "Seite",
    "exitDescription": "Editor schliessen ohne veröffentlichen",
    "exitTitle": "Schliessen",
    "failedToConvert": "Das Element konnte nicht konvertiert werden.",
    "failedToTransform": "Die Aktion «@name» konnte nicht ausgeführt werden.",
    "fatalErrorButton": "Trotzdem fortfahren...",
    "fatalErrorText": "Leider hat blökkli einen schwerwiegenden Fehler festgestellt. Möglicherweise können Sie den Editor weiterhin verwenden, aber es kann sein, dass nicht alles wie erwartet funktioniert.",
    "fatalErrorTitle": "blökkli funktioniert nicht mehr",
    "featureHelpShortcuts": "Tastenkombinationen",
    "featureHelpTitle": "Hilfe",
    "feature_add-list_setting_orientation_label": "Block-Leiste",
    "feature_add-list_setting_orientation_option_horizontal": "Horizontal",
    "feature_add-list_setting_orientation_option_sidebar": "Sidebar",
    "feature_add-list_setting_orientation_option_vertical": "Vertikal",
    "feature_artboard_setting_momentum_label": "Sanftes Scrollen verwenden",
    "feature_artboard_setting_persist_label": "Position und Zoom speichern",
    "feature_artboard_setting_scrollSpeed_label": "Scrollgeschwindigkeit der Vorschau",
    "feature_block-add-list_setting_hideDisabledBlocks_label": "Blöcke ausblenden, die nicht hinzugefügt werden können",
    "feature_clipboard_setting_openSidebarOnPaste_label": "Seitenleiste beim Einfügen aus der Zwischenablage öffnen",
    "feature_history_setting_useMouseButtons_label": "Maustasten für Rückgängig/Wiederherstellen verwenden",
    "feature_import-existing_setting_showDialogWhenEmpty_label": "Importdialog beim Start anzeigen, wenn die Seite leer ist",
    "feature_publish_setting_closeAfterPublish_label": "Nach «Veröffentlichen» den Editor schliessen",
    "feature_settings_setting_lowPerformanceMode_label": "Modus für niedrige Leistung aktivieren",
    "feature_settings_setting_resetAllSettings_label": "Alle Einstellungen zurücksetzen",
    "feature_settings_setting_useAnimations_label": "Animationen verwenden",
    "fieldAreasHide": "Blockfelder verstecken",
    "fieldAreasShow": "Blockfelder anzeigen",
    "fieldAreasToggle": "Blockfelder anzeigen",
    "fieldAreasTourText": "Aktivieren Sie, dass Bereiche ein- oder ausgeblendet werden, in denen Blöcke platziert werden können. ",
    "fieldIsRequired": "Feld darf nicht leer sein",
    "fragmentsAddFragmentAction": "Fragment hinzufügen",
    "fragmentsAddFragmentDescription": "<p>Ziehen Sie das Symbol auf die Seite, um einen Fragmentblock hinzuzufügen.</p><p>Fragmente sind wiederverwendbare Blöcke, die immer denselben Inhalt darstellen.</p>",
    "fragmentsPlaceDialogLead": "Wählen Sie ein Block-Fragment aus.",
    "fragmentsPlaceDialogSearchLabel": "Fragmente filtern",
    "fragmentsPlaceDialogSearchPlaceholder": "Fragmente durchsuchen",
    "fragmentsPlaceDialogSubmitButton": "Ausgewähltes Fragment hinzufügen",
    "fragmentsPlaceDialogTitle": "Fragment hinzufügen",
    "gridHide": "Spalten verstecken",
    "gridShow": "Spalten anzeigen",
    "gridToggle": "Spalten anzeigen",
    "gridTourText": "Zeigt die Layout-Spalten als Overlay über die Seite an.",
    "helpTourText": "Zeigt eine Liste von verfügbaren Tastenkombinationen an.",
    "history": "Änderungen",
    "historyCurrentRevision": "Aktuelle Revision",
    "historyEmpty": "Es gibt noch keine Änderungen.",
    "historyRedo": "Wiederholen",
    "historyRedoTourText": "Machen Sie die vorherige Änderung rückgängig.",
    "historyShowMore": "@count weitere anzeigen",
    "historyTourText": "Zeigt eine Liste aller Änderungen die gemacht wurden. Springen Sie beliebig zwischen Änderungen vor und zurück.",
    "historyUndo": "Rückgängig",
    "historyUndoTourText": "Letzte Änderung rückgängig machen.",
    "importExistingDescription": "Von einer bestehenden Seite importieren",
    "importExistingDialogLead": "Importieren Sie Inhalte von einer bestehenden Seite. Die Elemente werden an das Ende der Liste hinzugefügt. Diese Aktion kann rückgängig gemacht werden.",
    "importExistingDialogSubmit": "Inhalte importieren",
    "importExistingDialogTitle": "Von bestehender Seite importieren",
    "importExistingError": "Inhalte konnten nicht übernommen werden.",
    "importExistingFieldsLabel": "Welche Inhalte möchten Sie importieren?",
    "importExistingPagesLabel": "Von welcher Seite möchten Sie importieren?",
    "importExistingResultsTitle": "@count von @total Seiten",
    "importExistingSearchPlaceholder": "Seiten durchsuchen",
    "importExistingSuccess": "Inhalte erfolgreich übernommen.",
    "importExistingTitle": "Importieren...",
    "libraryAdd": "Zur Bibliothek hinzufügen",
    "libraryAddDescription": "Fügen Sie einen wiederverwendbaren Block aus der Bibliothek hinzu.",
    "libraryAddFromLibrary": "Aus der Bibliothek hinzufügen",
    "libraryDetach": "Von der Bibliothek loslösen",
    "libraryDialogDescriptionLabel": "Beschreibung",
    "libraryDialogLead": "Wenn Sie ein Element zur Bibliothek hinzufügen können Sie diesen auf mehreren Seiten gleichzeitig verwenden. Änderungen an diesem Element sind dann sofort auf allen Seiten publiziert.",
    "libraryDialogSubmit": "Zur Bibliothek hinzufügen",
    "libraryDialogTitle": "Zur Bibliothek hinzufügen",
    "libraryDialogTitleInputPlaceholder": "z.B. Teaser Kampagne 2023",
    "libraryError": "Das Element konnte nicht wiederverwendbar gemacht werden.",
    "libraryItemEditOverlayBack": "Zurück zur Seite",
    "libraryItemEditOverlayTitle": "Wiederverwendbaren Block bearbeiten",
    "libraryPlaceBundleSelectLabel": "Typ",
    "libraryPlaceDialogLead": "Wählen Sie einen wiederverwendbaren Block aus der Bibliothek, um ihn zu Ihrem Layout hinzuzufügen. Sie können den Block später wieder entfernen.",
    "libraryPlaceDialogSubmit": "Wiederverwendbaren Block hinzufügen",
    "libraryPlaceDialogTitle": "Block aus Bibliothek hinzufügen",
    "libraryPlaceSearchInputPlaceholder": "Suchbegriff eingeben",
    "libraryPlaceSearchLabel": "Bibliothek durchsuchen",
    "libraryPreviewLabel": "Vorschau",
    "maskHide": "Nicht-editierbare Bereiche verstecken",
    "maskShow": "Nicht-editierbare Bereiche anzeigen",
    "maskToggle": "Nicht-editierbare Bereiche anzeigen",
    "maskTourText": "Wechseln Sie zwischen Anzeigen und Verbergen nicht bearbeitbarer Teile der Seite.",
    "mediaLibrary": "Medienbibliothek",
    "mediaLibraryReplaceFailed": "Das Medienelement konnte nicht ersetzt werden.",
    "mediaLibraryReplaceMedia": "Medienelement ersetzen",
    "mediaLibraryTourText": "Suchen Sie nach Medien wie Bildern und ziehen Sie diese in die Seite.",
    "multipleItemsLabel": "Elemente",
    "optionBkVisibleLanguagesAll": "Alle Sprachen",
    "optionsCommand.selectCheckboxValue": "«@value» in «@option» auswählen",
    "optionsCommand.setOption": "Option «@option» «@value» setzen",
    "optionsCommand.unselectCheckboxValue": "«@value» in «@option» abwählen",
    "ownershipError": "Fehler beim Zuweisen",
    "ownershipNote": "Diese Seite wird aktuell von @name bearbeitet. Änderungen können nur von einer Person gleichzeitig durchgeführt werden.",
    "ownershipSuccess": "Sie sind nun der Besitzer.",
    "ownershipTakeOwnership": "Mir zuweisen",
    "pageIsNotPublished": "Seite ist nicht publiziert",
    "pageIsPublished": "Seite ist publiziert",
    "pageIsPublishedWithPendingChanges": "Seite ist publiziert (Änderungen ausstehend)",
    "previewDialogLead": "Scannen Sie den QR-Code mit Ihrem Smartphone um die Vorschau zu öffnen.",
    "previewDialogTitle": "Vorschau mit Smartphone",
    "previewNewWindow": "Vorschau (neues Fenster)",
    "previewNewWindowTourText": "Öffnet eine Vorschau der aktuellen Änderungen in einem neuen Fenster.",
    "previewQrCodeText": "Sie können den Link auch kopieren und teilen.",
    "previewWithSmartphone": "Vorschau (mit Smartphone)",
    "previewWithSmartphoneTourText": "Zeigt einen QR-Code an, um eine Vorschau der Änderungen mit Ihrem Smartphone zu öffnen.",
    "proxyViewHide": "Inhaltsvorschau anzeigen",
    "proxyViewShow": "Strukturierte Vorschau anzeigen",
    "proxyViewToggle": "Vorschau wechseln",
    "proxyViewTourText": "Stellt die Inhaltsblöcke in strukturierter Form dar.",
    "publishAndCloseLabel": "Veröffentlichen & Schliessen",
    "publishAndCloseLabelUnpublished": "Speichern & Schliessen",
    "publishDescription": "Alle Änderungen veröffentlichen",
    "publishDescriptionUnpublished": "Alle Änderungen speichern ohne die Seite zu publizieren",
    "publishError": "Änderungen konnten nicht publiziert werden.",
    "publishLabel": "Veröffentlichen",
    "publishLabelUnpublished": "Speichern",
    "publishSuccess": "Änderungen erfolgreich publiziert.",
    "responsivePreviewCustomViewport": "Benutzerdefiniert",
    "responsivePreviewTitle": "Responsive Vorschau",
    "responsivePreviewTourText": "Sehen Sie, wie Ihre Änderungen auf kleineren Bildschirmen, wie Smartphones, aussehen. Klicken Sie auf den \"Loslösen\"-Button, um zusätzliche Ansichtsgrössen auswählen zu können.",
    "revertDialogLead": "Damit werden alle Änderungen gelöscht und der aktuell publizierte Stand wiederhergestellt. Diese Aktion kann nicht rückgängig gemacht werden.",
    "revertDialogSubmit": "Änderungen verwerfen",
    "revertDialogTitle": "Änderungen unwiderruflich verwerfen",
    "revertError": "Änderungen konnten nicht verworfen werden.",
    "revertMenuDescription": "Aktuell veröffentlichten Zustand wiederherstellen",
    "revertMenuTitle": "Verwerfen...",
    "revertSuccess": "Alle Änderungen wurden verworfen.",
    "save": "Speichern",
    "searchBoxNoResultsFound": "Keine Resultate gefunden",
    "searchBoxOnThisPage": "Auf dieser Seite",
    "searchBoxPlaceholder": "Suchbegriff eingeben",
    "searchToolbarLabel": "Inhalte suchen",
    "searchTourText": "Finden Sie Blöcke auf der aktuellen Seite oder andere bestehende Inhalte wie Bilder oder Dokumente, um sie als Blöcke auf der Seite hinzuzufügen.",
    "settingsAdvanced": "Erweitert",
    "settingsAppearance": "Darstellung",
    "settingsArtboard": "Vorschau",
    "settingsBehaviour": "Verhalten",
    "settingsDialogTitle": "Einstellungen",
    "settingsMenuDescription": "Persönliche Einstellungen für den Editor",
    "settingsMenuTitle": "Einstellungen",
    "shortcutGroupBlocks": "Blöcke",
    "shortcutGroupGeneral": "Allgemein",
    "shortcutGroupUi": "Oberfläche",
    "sidebar.hide": "@title ausblenden",
    "sidebar.show": "@title anzeigen",
    "singleComment": "Kommentar",
    "structureToolbarLabel": "Struktur",
    "structureTourText": "Zeigt eine strukturierte Liste aller Blöcke auf der aktuellen Seite. Klicken Sie auf einen Block, um ihn auf der Seite anzuzeigen.",
    "systemRequirementsDialogButton": "Trotzdem fortfahren",
    "systemRequirementsDialogLead": "blökkli hat festgestellt, dass Ihr Browser eine oder mehrere Systemanforderungen nicht erfüllt. Für die beste Erfahrung verwenden Sie bitte einen Browser, der alle aufgeführten Anforderungen unterstützt.",
    "systemRequirementsDialogText": "blökkli wurde für die neuesten Versionen von Chrome, Firefox, Edge und Safari getestet und optimiert. Funktionen wie WebGL werden in der Regel unterstützt, können jedoch manchmal aus Leistungs- oder Sicherheitsgründen deaktiviert sein.",
    "systemRequirementsDialogTitle": "Systemanforderungen",
    "systemRequirementsWebglText": "blökkli verwendet WebGL für die schnelle Darstellung von GUI-Elementen wie ausgewählten Blöcken und Drag-and-Drop-Indikatoren. Wenn WebGL nicht unterstützt wird, verwendet blökkli einen Fallback, der langsamer ist und nicht alle Funktionen unterstützt.",
    "theme": "Farben",
    "themeTourText": "Ändern Sie das Theme und generieren Sie eine Theme-Datei.",
    "touchBarCancelDragging": "Platzieren abbrechen",
    "touchBarFinishSelecting": "Mehrfachauswahl beenden",
    "tourDescription": "Entdecken Sie die Funktionen vom Editor.",
    "tourIntro": "Lernen Sie die wichtigsten Funktionen des Editors kennen und erfahren Sie, wie Sie mit blökkli loslegen können.",
    "tourLabel": "Tour machen",
    "tourNext": "Weiter",
    "tourPrev": "Zurück",
    "tourStartButton": "Tour starten",
    "transformTo": "Weitere Aktionen",
    "translationsBannerButton": "Originalsprache bearbeiten",
    "translationsBannerText": "Sie bearbeiten die Übersetzung <strong>«@language»</strong>. Einige Funktionen wie das Hinzufügen, Verschieben oder Löschen von Blöcken sind nicht verfügbar.",
    "translationsBatchTranslateMenuDescription": "Alle Elemente übersetzen",
    "translationsBatchTranslateMenuTitle": "Übersetzen...",
    "translationsItemAction": "Übersetzen",
    "translationsTourText": "Wechseln Sie schnell zwischen verfügbaren Übersetzungen. Eine graue Sprache zeigt an, dass der Inhalt noch nicht übersetzt wurde. Ein Klick darauf öffnet das Formular, um eine neue Übersetzung für diese Sprache zu erstellen.",
    "translationsTourTitle": "Übersetzungen",
    "validationsNoneFound": "Keine Validierungsfehler gefunden.",
    "validationsToolbarLabel": "Validierungen",
    "validationsTourText": "Zeigt Validierungsfehler für Inhalte auf der aktuellen Seite an."
  },
  "fr": {
    "addBlockCommand.appendInField": "",
    "addBlockCommand.appendRoot": "",
    "addBlockCommand.insertInField": "",
    "addCommentToItem": "Commenter",
    "addListInputPlaceholder": "",
    "addListItemFavoriteAdd": "",
    "addListItemFavoriteRemove": "",
    "addListSidebarTitle": "",
    "addListTourText": "",
    "all": "",
    "artboard": "",
    "artboardOverviewHide": "",
    "artboardOverviewShow": "",
    "artboardOverviewToggle": "",
    "artboardOverviewTourText": "",
    "artboardResetZoom": "",
    "artboardScaleToFit": "",
    "artboardScrollDown": "",
    "artboardScrollOnePageDown": "",
    "artboardScrollOnePageUp": "",
    "artboardScrollToEnd": "",
    "artboardScrollToTop": "",
    "artboardScrollUp": "",
    "artboardToolbarButtonTourText": "",
    "assistantAddAction": "",
    "assistantAddActionDescription": "",
    "assistantAddResultError": "",
    "assistantDialogLead": "",
    "assistantDialogSubmit": "",
    "assistantDialogTitle": "",
    "assistantPromptLabel": "",
    "assistantPromptPlaceholder": "",
    "blockAddListTourText": "",
    "blockAddListTourTitle": "",
    "blockOption_bkHiddenGlobally_label": "",
    "blockOption_bkVisibleLanguages_label": "",
    "cancel": "Annuler",
    "clipboard": "Presse-papiers",
    "clipboardCopyShortcutHelp": "",
    "clipboardEmpty": "Aucun élément dans le presse-papiers",
    "clipboardExplanation": "<p>\n    Utilisez Ctrl-V sur la page pour coller du contenu. Ils\n    seront alors affichés ici.\n  </p>\n  <p>\n    Utilisez Ctrl-F pour rechercher du contenu existant et le copier\n    dans le presse-papiers.\n  </p>",
    "clipboardPastePlaceholder": "Paste text or media here",
    "clipboardPasteShortcutHelp": "",
    "clipboardTourText": "",
    "close": "Fermer",
    "commandGroup.action": "",
    "commandGroup.add": "",
    "commandGroup.misc": "",
    "commandGroup.selection": "",
    "commandGroup.ui": "",
    "commandPalette.inputPlaceholder": "",
    "commandPaletteOpen": "",
    "commandPaletteTourText": "",
    "commentAdd": "",
    "commentBody": "Commentaire",
    "commentBodyPlaceholder": "",
    "commentSave": "Sauvegarder le commentaire",
    "comments": "Commentaires",
    "commentsMarkAsResolved": "",
    "commentsTourText": "",
    "convertTo": "Convertir en...",
    "deleteButton": "Supprimer",
    "deleteError": "L'élément n'a pas pu être supprimé.",
    "droppableEditFormSave": "",
    "duplicate": "Dupliquer",
    "duplicateError": "Les éléments n'ont pas pu être dupliqués.",
    "edit": "Modifier",
    "editFormBlockAdd": "",
    "editFormBlockEdit": "",
    "editFormBlockTranslate": "",
    "editFormEntityEdit": "",
    "editFormEntityTranslate": "",
    "editIndicatorLabel": "Modifier les éléments",
    "editableCommandEdit": "",
    "entityTitleTourText": "",
    "entityTitleTourTitle": "",
    "exitDescription": "Fermer l’éditeur sans publier",
    "exitTitle": "Fermer",
    "failedToConvert": "L'élément n'a pas pu être converti.",
    "failedToTransform": "The action \"@name\" could not be executed.",
    "fatalErrorButton": "",
    "fatalErrorText": "",
    "fatalErrorTitle": "",
    "featureHelpShortcuts": "",
    "featureHelpTitle": "",
    "feature_add-list_setting_orientation_label": "",
    "feature_add-list_setting_orientation_option_horizontal": "",
    "feature_add-list_setting_orientation_option_sidebar": "Encadré",
    "feature_add-list_setting_orientation_option_vertical": "",
    "feature_artboard_setting_momentum_label": "",
    "feature_artboard_setting_persist_label": "",
    "feature_artboard_setting_scrollSpeed_label": "",
    "feature_block-add-list_setting_hideDisabledBlocks_label": "",
    "feature_clipboard_setting_openSidebarOnPaste_label": "",
    "feature_history_setting_useMouseButtons_label": "",
    "feature_import-existing_setting_showDialogWhenEmpty_label": "",
    "feature_publish_setting_closeAfterPublish_label": "",
    "feature_settings_setting_lowPerformanceMode_label": "",
    "feature_settings_setting_resetAllSettings_label": "",
    "feature_settings_setting_useAnimations_label": "",
    "fieldAreasHide": "Masquer les zones de contenu",
    "fieldAreasShow": "Afficher les zones de contenu",
    "fieldAreasToggle": "",
    "fieldAreasTourText": "",
    "fieldIsRequired": "Ce champ est obligatoire",
    "fragmentsAddFragmentAction": "",
    "fragmentsAddFragmentDescription": "",
    "fragmentsPlaceDialogLead": "",
    "fragmentsPlaceDialogSearchLabel": "",
    "fragmentsPlaceDialogSearchPlaceholder": "",
    "fragmentsPlaceDialogSubmitButton": "",
    "fragmentsPlaceDialogTitle": "",
    "gridHide": "Masquer la grille",
    "gridShow": "Afficher la grille",
    "gridToggle": "",
    "gridTourText": "",
    "helpTourText": "",
    "history": "Historique",
    "historyCurrentRevision": "Révision actuelle",
    "historyEmpty": "",
    "historyRedo": "",
    "historyRedoTourText": "",
    "historyShowMore": "@count autres à afficher",
    "historyTourText": "",
    "historyUndo": "",
    "historyUndoTourText": "",
    "importExistingDescription": "Importer d’une page existante",
    "importExistingDialogLead": "Importez du contenu d’une page existante. Les éléments seront ajoutés à la fin de la liste. Cette action peut être annulée.",
    "importExistingDialogSubmit": "Importer le contenu",
    "importExistingDialogTitle": "Importer d’une page existante",
    "importExistingError": "Le contenu n’a pas pu être importé.",
    "importExistingFieldsLabel": "Quel contenu souhaitez-vous importer ?",
    "importExistingPagesLabel": "De quelle page souhaitez-vous importer ?",
    "importExistingResultsTitle": "@count sur @total pages",
    "importExistingSearchPlaceholder": "Rechercher des pages",
    "importExistingSuccess": "Contenu importé avec succès.",
    "importExistingTitle": "Importer...",
    "libraryAdd": "Ajouter à la bibliothèque",
    "libraryAddDescription": "",
    "libraryAddFromLibrary": "",
    "libraryDetach": "",
    "libraryDialogDescriptionLabel": "Description",
    "libraryDialogLead": "En ajoutant un élément à la bibliothèque, vous pouvez l’utiliser sur plusieurs pages à la fois. Les modifications apportées à cet élément seront alors immédiatement publiées sur toutes les pages.",
    "libraryDialogSubmit": "Ajouter à la bibliothèque",
    "libraryDialogTitle": "Ajouter à la bibliothèque",
    "libraryDialogTitleInputPlaceholder": "Ex. : Teaser campagne 2023",
    "libraryError": "L'élément n’a pas pu être rendu réutilisable.",
    "libraryItemEditOverlayBack": "",
    "libraryItemEditOverlayTitle": "",
    "libraryPlaceBundleSelectLabel": "",
    "libraryPlaceDialogLead": "",
    "libraryPlaceDialogSubmit": "",
    "libraryPlaceDialogTitle": "",
    "libraryPlaceSearchInputPlaceholder": "",
    "libraryPlaceSearchLabel": "",
    "libraryPreviewLabel": "Aperçu",
    "maskHide": "Masquer les champs de contenu",
    "maskShow": "Afficher les champs de contenu",
    "maskToggle": "",
    "maskTourText": "",
    "mediaLibrary": "",
    "mediaLibraryReplaceFailed": "",
    "mediaLibraryReplaceMedia": "",
    "mediaLibraryTourText": "",
    "multipleItemsLabel": "Éléments",
    "optionBkVisibleLanguagesAll": "",
    "optionsCommand.selectCheckboxValue": "",
    "optionsCommand.setOption": "",
    "optionsCommand.unselectCheckboxValue": "",
    "ownershipError": "Erreur lors de l’attribution",
    "ownershipNote": "Cette page est actuellement en cours de modification par @name. Les modifications ne peuvent être effectuées que par une personne à la fois.",
    "ownershipSuccess": "Vous en êtes maintenant le propriétaire.",
    "ownershipTakeOwnership": "Me l’attribuer",
    "pageIsNotPublished": "La page n’est pas publiée",
    "pageIsPublished": "La page est publiée",
    "pageIsPublishedWithPendingChanges": "La page est publiée (modifications en attente)",
    "previewDialogLead": "Scannez le code QR avec votre smartphone pour ouvrir l’aperçu.",
    "previewDialogTitle": "Aperçu avec smartphone",
    "previewNewWindow": "Aperçu (nouvelle fenêtre)",
    "previewNewWindowTourText": "",
    "previewQrCodeText": "",
    "previewWithSmartphone": "Aperçu (avec smartphone)",
    "previewWithSmartphoneTourText": "",
    "proxyViewHide": "",
    "proxyViewShow": "",
    "proxyViewToggle": "",
    "proxyViewTourText": "",
    "publishAndCloseLabel": "",
    "publishAndCloseLabelUnpublished": "",
    "publishDescription": "Rendre toutes les modifications publiques",
    "publishDescriptionUnpublished": "",
    "publishError": "Les modifications n’ont pas pu être publiées.",
    "publishLabel": "Publier",
    "publishLabelUnpublished": "",
    "publishSuccess": "Modifications publiées avec succès.",
    "responsivePreviewCustomViewport": "",
    "responsivePreviewTitle": "",
    "responsivePreviewTourText": "",
    "revertDialogLead": "Cela supprimera toutes les modifications et restaurera l’état actuellement publié. Cette action est irréversible.",
    "revertDialogSubmit": "Annuler les modifications",
    "revertDialogTitle": "Annuler les modifications de façon irréversible",
    "revertError": "Les modifications n’ont pas pu être annulées.",
    "revertMenuDescription": "Restaurer l’état actuellement publié",
    "revertMenuTitle": "Annuler...",
    "revertSuccess": "Toutes les modifications ont été annulées.",
    "save": "Save",
    "searchBoxNoResultsFound": "Aucun résultat trouvé",
    "searchBoxOnThisPage": "Sur cette page",
    "searchBoxPlaceholder": "Entrer un terme de recherche",
    "searchToolbarLabel": "Rechercher des contenus",
    "searchTourText": "",
    "settingsAdvanced": "",
    "settingsAppearance": "",
    "settingsArtboard": "",
    "settingsBehaviour": "Comportement",
    "settingsDialogTitle": "Paramètres",
    "settingsMenuDescription": "Paramètres personnels pour l’éditeur",
    "settingsMenuTitle": "Paramètres",
    "shortcutGroupBlocks": "",
    "shortcutGroupGeneral": "",
    "shortcutGroupUi": "",
    "sidebar.hide": "",
    "sidebar.show": "",
    "singleComment": "",
    "structureToolbarLabel": "Structure",
    "structureTourText": "",
    "systemRequirementsDialogButton": "",
    "systemRequirementsDialogLead": "",
    "systemRequirementsDialogText": "",
    "systemRequirementsDialogTitle": "",
    "systemRequirementsWebglText": "",
    "theme": "",
    "themeTourText": "",
    "touchBarCancelDragging": "",
    "touchBarFinishSelecting": "",
    "tourDescription": "",
    "tourIntro": "",
    "tourLabel": "",
    "tourNext": "",
    "tourPrev": "",
    "tourStartButton": "",
    "transformTo": "Other actions",
    "translationsBannerButton": "",
    "translationsBannerText": "",
    "translationsBatchTranslateMenuDescription": "Traduire tous les éléments",
    "translationsBatchTranslateMenuTitle": "Traduire...",
    "translationsItemAction": "Traduire...",
    "translationsTourText": "",
    "translationsTourTitle": "",
    "validationsNoneFound": "Aucune erreur de validation trouvée.",
    "validationsToolbarLabel": "Validations",
    "validationsTourText": ""
  },
  "it": {
    "addBlockCommand.appendInField": "",
    "addBlockCommand.appendRoot": "",
    "addBlockCommand.insertInField": "",
    "addCommentToItem": "Commenta",
    "addListInputPlaceholder": "",
    "addListItemFavoriteAdd": "",
    "addListItemFavoriteRemove": "",
    "addListSidebarTitle": "",
    "addListTourText": "",
    "all": "",
    "artboard": "",
    "artboardOverviewHide": "",
    "artboardOverviewShow": "",
    "artboardOverviewToggle": "",
    "artboardOverviewTourText": "",
    "artboardResetZoom": "",
    "artboardScaleToFit": "",
    "artboardScrollDown": "",
    "artboardScrollOnePageDown": "",
    "artboardScrollOnePageUp": "",
    "artboardScrollToEnd": "",
    "artboardScrollToTop": "",
    "artboardScrollUp": "",
    "artboardToolbarButtonTourText": "",
    "assistantAddAction": "",
    "assistantAddActionDescription": "",
    "assistantAddResultError": "",
    "assistantDialogLead": "",
    "assistantDialogSubmit": "",
    "assistantDialogTitle": "",
    "assistantPromptLabel": "",
    "assistantPromptPlaceholder": "",
    "blockAddListTourText": "",
    "blockAddListTourTitle": "",
    "blockOption_bkHiddenGlobally_label": "",
    "blockOption_bkVisibleLanguages_label": "",
    "cancel": "Annulla",
    "clipboard": "Appunti",
    "clipboardCopyShortcutHelp": "",
    "clipboardEmpty": "Nessun elemento negli appunti",
    "clipboardExplanation": "<p>\n    Utilizzare Ctrl-V sulla pagina per incollare i contenuti. Questi\n    saranno poi visualizzati qui.\n  </p>\n  <p>\n    Utilizzare Ctrl-F per cercare contenuti esistenti e incollarli negli appunti.\n  </p>",
    "clipboardPastePlaceholder": "Paste text or media here",
    "clipboardPasteShortcutHelp": "",
    "clipboardTourText": "",
    "close": "Chiudi",
    "commandGroup.action": "",
    "commandGroup.add": "",
    "commandGroup.misc": "",
    "commandGroup.selection": "",
    "commandGroup.ui": "",
    "commandPalette.inputPlaceholder": "",
    "commandPaletteOpen": "",
    "commandPaletteTourText": "",
    "commentAdd": "",
    "commentBody": "Commento",
    "commentBodyPlaceholder": "",
    "commentSave": "Salva commento",
    "comments": "Commenti",
    "commentsMarkAsResolved": "",
    "commentsTourText": "",
    "convertTo": "Converti in...",
    "deleteButton": "Elimina",
    "deleteError": "L'elemento non può essere rimosso.",
    "droppableEditFormSave": "",
    "duplicate": "Duplica",
    "duplicateError": "Gli elementi non possono essere duplicati.",
    "edit": "Modifica",
    "editFormBlockAdd": "",
    "editFormBlockEdit": "",
    "editFormBlockTranslate": "",
    "editFormEntityEdit": "",
    "editFormEntityTranslate": "",
    "editIndicatorLabel": "Modifica elementi",
    "editableCommandEdit": "",
    "entityTitleTourText": "",
    "entityTitleTourTitle": "",
    "exitDescription": "Chiudi l'editor senza pubblicare",
    "exitTitle": "Chiudi",
    "failedToConvert": "L'elemento non può essere convertito.",
    "failedToTransform": "The action \"@name\" could not be executed.",
    "fatalErrorButton": "",
    "fatalErrorText": "",
    "fatalErrorTitle": "",
    "featureHelpShortcuts": "",
    "featureHelpTitle": "",
    "feature_add-list_setting_orientation_label": "",
    "feature_add-list_setting_orientation_option_horizontal": "",
    "feature_add-list_setting_orientation_option_sidebar": "",
    "feature_add-list_setting_orientation_option_vertical": "",
    "feature_artboard_setting_momentum_label": "",
    "feature_artboard_setting_persist_label": "",
    "feature_artboard_setting_scrollSpeed_label": "",
    "feature_block-add-list_setting_hideDisabledBlocks_label": "",
    "feature_clipboard_setting_openSidebarOnPaste_label": "",
    "feature_history_setting_useMouseButtons_label": "",
    "feature_import-existing_setting_showDialogWhenEmpty_label": "",
    "feature_publish_setting_closeAfterPublish_label": "",
    "feature_settings_setting_lowPerformanceMode_label": "",
    "feature_settings_setting_resetAllSettings_label": "",
    "feature_settings_setting_useAnimations_label": "",
    "fieldAreasHide": "Nascondi aree di contenuto",
    "fieldAreasShow": "Mostra aree di contenuto",
    "fieldAreasToggle": "",
    "fieldAreasTourText": "",
    "fieldIsRequired": "Questo campo è obbligatorio",
    "fragmentsAddFragmentAction": "",
    "fragmentsAddFragmentDescription": "",
    "fragmentsPlaceDialogLead": "",
    "fragmentsPlaceDialogSearchLabel": "",
    "fragmentsPlaceDialogSearchPlaceholder": "",
    "fragmentsPlaceDialogSubmitButton": "",
    "fragmentsPlaceDialogTitle": "",
    "gridHide": "Nascondi griglia",
    "gridShow": "Mostra griglia",
    "gridToggle": "",
    "gridTourText": "",
    "helpTourText": "",
    "history": "Cronologia",
    "historyCurrentRevision": "Revisione attuale",
    "historyEmpty": "",
    "historyRedo": "",
    "historyRedoTourText": "",
    "historyShowMore": "Mostra altre @count",
    "historyTourText": "",
    "historyUndo": "",
    "historyUndoTourText": "",
    "importExistingDescription": "Importa da una pagina esistente",
    "importExistingDialogLead": "Importa contenuti da una pagina esistente. Gli elementi verranno aggiunti alla fine della lista. Questa azione può essere annullata.",
    "importExistingDialogSubmit": "Importa contenuti",
    "importExistingDialogTitle": "Importa da una pagina esistente",
    "importExistingError": "I contenuti non possono essere importati.",
    "importExistingFieldsLabel": "Quali contenuti vuoi importare?",
    "importExistingPagesLabel": "Da quale pagina vuoi importare?",
    "importExistingResultsTitle": "@count di @total pagine",
    "importExistingSearchPlaceholder": "Cerca pagine",
    "importExistingSuccess": "Contenuti importati con successo.",
    "importExistingTitle": "Importa...",
    "libraryAdd": "Aggiungi alla biblioteca",
    "libraryAddDescription": "",
    "libraryAddFromLibrary": "",
    "libraryDetach": "",
    "libraryDialogDescriptionLabel": "Descrizione",
    "libraryDialogLead": "Aggiungendo un elemento alla biblioteca, puoi utilizzarlo su più pagine contemporaneamente. Le modifiche a questo elemento saranno pubblicate immediatamente su tutte le pagine.",
    "libraryDialogSubmit": "Aggiungi alla biblioteca",
    "libraryDialogTitle": "Aggiungi alla biblioteca",
    "libraryDialogTitleInputPlaceholder": "es. Anteprima Campagna 2023",
    "libraryError": "L'elemento non può essere reso riutilizzabile.",
    "libraryItemEditOverlayBack": "",
    "libraryItemEditOverlayTitle": "",
    "libraryPlaceBundleSelectLabel": "",
    "libraryPlaceDialogLead": "",
    "libraryPlaceDialogSubmit": "",
    "libraryPlaceDialogTitle": "",
    "libraryPlaceSearchInputPlaceholder": "",
    "libraryPlaceSearchLabel": "",
    "libraryPreviewLabel": "Anteprima",
    "maskHide": "Nascondi campi di contenuto",
    "maskShow": "Mostra campi di contenuto",
    "maskToggle": "",
    "maskTourText": "",
    "mediaLibrary": "",
    "mediaLibraryReplaceFailed": "",
    "mediaLibraryReplaceMedia": "",
    "mediaLibraryTourText": "",
    "multipleItemsLabel": "Elementi",
    "optionBkVisibleLanguagesAll": "",
    "optionsCommand.selectCheckboxValue": "",
    "optionsCommand.setOption": "",
    "optionsCommand.unselectCheckboxValue": "",
    "ownershipError": "Errore nell'assegnazione",
    "ownershipNote": "Questa pagina è attualmente in lavorazione da @name. Le modifiche possono essere effettuate solo da una persona alla volta.",
    "ownershipSuccess": "Ora sei il proprietario.",
    "ownershipTakeOwnership": "Assegna a me",
    "pageIsNotPublished": "Pagina non pubblicata",
    "pageIsPublished": "Pagina pubblicata",
    "pageIsPublishedWithPendingChanges": "Pagina pubblicata (modifiche in attesa)",
    "previewDialogLead": "Scansiona il codice QR con il tuo smartphone per aprire l'anteprima.",
    "previewDialogTitle": "Anteprima con smartphone",
    "previewNewWindow": "Anteprima (nuova finestra)",
    "previewNewWindowTourText": "",
    "previewQrCodeText": "",
    "previewWithSmartphone": "Anteprima (con smartphone)",
    "previewWithSmartphoneTourText": "",
    "proxyViewHide": "",
    "proxyViewShow": "",
    "proxyViewToggle": "",
    "proxyViewTourText": "",
    "publishAndCloseLabel": "",
    "publishAndCloseLabelUnpublished": "",
    "publishDescription": "Rendi pubbliche tutte le modifiche",
    "publishDescriptionUnpublished": "",
    "publishError": "Le modifiche non possono essere pubblicate.",
    "publishLabel": "Pubblica",
    "publishLabelUnpublished": "",
    "publishSuccess": "Modifiche pubblicate con successo.",
    "responsivePreviewCustomViewport": "",
    "responsivePreviewTitle": "",
    "responsivePreviewTourText": "",
    "revertDialogLead": "Questo eliminerà tutte le modifiche e ripristinerà lo stato attualmente pubblicato. Questa azione non può essere annullata.",
    "revertDialogSubmit": "Scarta modifiche",
    "revertDialogTitle": "Scarta irrevocabilmente le modifiche",
    "revertError": "Le modifiche non possono essere scartate.",
    "revertMenuDescription": "Ripristina lo stato attualmente pubblicato",
    "revertMenuTitle": "Annulla...",
    "revertSuccess": "Tutte le modifiche sono state scartate.",
    "save": "Save",
    "searchBoxNoResultsFound": "Nessun risultato trovato",
    "searchBoxOnThisPage": "Su questa pagina",
    "searchBoxPlaceholder": "Inserisci un termine di ricerca",
    "searchToolbarLabel": "Cerca contenuti",
    "searchTourText": "",
    "settingsAdvanced": "",
    "settingsAppearance": "",
    "settingsArtboard": "",
    "settingsBehaviour": "Comportamento",
    "settingsDialogTitle": "Impostazioni",
    "settingsMenuDescription": "Impostazioni personali per l'editor",
    "settingsMenuTitle": "Impostazioni",
    "shortcutGroupBlocks": "",
    "shortcutGroupGeneral": "",
    "shortcutGroupUi": "",
    "sidebar.hide": "",
    "sidebar.show": "",
    "singleComment": "",
    "structureToolbarLabel": "Struttura",
    "structureTourText": "",
    "systemRequirementsDialogButton": "",
    "systemRequirementsDialogLead": "",
    "systemRequirementsDialogText": "",
    "systemRequirementsDialogTitle": "",
    "systemRequirementsWebglText": "",
    "theme": "",
    "themeTourText": "",
    "touchBarCancelDragging": "",
    "touchBarFinishSelecting": "",
    "tourDescription": "",
    "tourIntro": "",
    "tourLabel": "",
    "tourNext": "",
    "tourPrev": "",
    "tourStartButton": "",
    "transformTo": "Other actions",
    "translationsBannerButton": "",
    "translationsBannerText": "",
    "translationsBatchTranslateMenuDescription": "Traduci tutti gli elementi",
    "translationsBatchTranslateMenuTitle": "Traduci...",
    "translationsItemAction": "Traduci...",
    "translationsTourText": "",
    "translationsTourTitle": "",
    "validationsNoneFound": "Nessun errore di validazione trovato.",
    "validationsToolbarLabel": "Validazioni",
    "validationsTourText": ""
  }
}