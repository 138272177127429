<template>
  <div class="teaser-slider overflow-hidden">
    <div v-if="slides?.length" class="md:container relative">
      <nav
        v-if="slides?.length && slides.length > 3"
        class="relative hidden md:block"
        :aria-label="
          $texts('accessibility.carousel_navigation', 'Karussell-Navigation')
        "
      >
        <div class="sr-only">
          {{
            $texts(
              'accessibility.carousel_navigation_information',
              'Verwenden Sie die Schaltflächen Zurück und Weiter, um zu navigieren',
            )
          }}
        </div>
        <div
          class="absolute top-0 right-full teaser-slider-button flex items-center justify-end pr-6"
          :class="aspectRatioClass"
        >
          <button
            ref="prevEl"
            class="disabled:text-stone-300 h-12 flex items-center justify-center"
          >
            <SpriteSymbol name="arrow-left" class="size-8" />
            <span class="sr-only">
              {{
                $texts(
                  'accessibility.carousel_previous',
                  'Zur vorherigen Slide',
                )
              }}
            </span>
          </button>
        </div>
        <div
          class="absolute top-0 left-full teaser-slider-button flex items-center justify-start pl-6"
          :class="aspectRatioClass"
        >
          <button
            ref="nextEl"
            class="disabled:text-stone-300 h-12 flex items-center justify-center"
          >
            <SpriteSymbol name="arrow-right" class="size-8" />
            <span class="sr-only">
              {{ $texts('accessibility.carousel_next', 'Zur nächsten Slide') }}
            </span>
          </button>
        </div>
      </nav>
      <div ref="wrapper" class="swiper relative">
        <ul
          ref="teaserList"
          class="teaser-slider-list"
          :class="{
            'md:container-grid': slides.length <= 3,
            'swiper-wrapper': slides.length > 3,
          }"
        >
          <li
            v-for="(item, i) in filteredSlides"
            :key="i"
            class="select-none drag-none"
            draggable="false"
            :class="{
              'swiper-slide': slides.length > 3,
              'col-span-4': slides.length <= 3,
            }"
          >
            <slot :item="item" />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts" generic="T">
import type { Swiper } from 'swiper'
import 'swiper/css'

const props = withDefaults(
  defineProps<{
    id: string
    aspectRatio?: 'exhibition' | 'square'
    slides?: T[] | null
  }>(),
  {
    aspectRatio: 'square',
    slides: () => [],
  },
)

const { isTouch } = useViewport()
const { $texts } = useEasyTexts()

const teaserList = ref<HTMLUListElement | null>(null)
const wrapper = ref<HTMLDivElement | null>(null)
const prevEl = ref<HTMLButtonElement | null>(null)
const nextEl = ref<HTMLButtonElement | null>(null)

const activeIndex = useState('slider:activeIndex:' + props.id, () => 0)
const scrollLeft = useState('slider:scrollLeft:' + props.id, () => 0)

const aspectRatioClass = computed(() => 'aspect-' + props.aspectRatio)

let swiper: Swiper | null = null

async function initSwiper() {
  if (!props.slides || !wrapper.value) {
    return
  }

  // No swiper needed when we only have 3 slides, since they all fit in the
  // available width anyway.
  if (props.slides.length <= 3) {
    return
  }

  // Initialize Swiper on desktop.
  const SwiperClass = await import('swiper').then((v) => v.Swiper)
  const Navigation = await import('swiper/modules').then((v) => v.Navigation)

  swiper = new SwiperClass(wrapper.value, {
    modules: [Navigation],
    initialSlide: activeIndex.value,
    slidesPerView: 3,
    spaceBetween: '32px',
    navigation: {
      nextEl: nextEl.value,
      prevEl: prevEl.value,
    },
  })
}

const filteredSlides = computed(
  () => props.slides?.filter((item) => item) || [],
)

watch(isTouch, async function (isTouch) {
  if (isTouch && swiper) {
    swiper.destroy(true, true)
  } else {
    await initSwiper()
  }
})

onMounted(async () => {
  if (!teaserList.value || !wrapper.value) {
    return
  }

  if (isTouch.value) {
    // Restore the scroll position.
    teaserList.value.scrollLeft = scrollLeft.value
    return
  }

  await initSwiper()
})

onBeforeUnmount(() => {
  if (!teaserList.value) {
    return
  }

  scrollLeft.value = teaserList.value.scrollLeft

  if (!swiper) {
    return
  }

  activeIndex.value = swiper.activeIndex

  swiper.destroy(true, false)
})
</script>

<style lang="postcss">
.teaser-slider {
  /* The width of a column in the grid. */
  /* We override the width because on small viewports the width is not based on the grid. */
  --slide-column-width: calc(
    (100vw - (2 * var(--root-container-spacer)) - 5 * var(--gap)) / 6
  );

  /* The number of columns a slide should span. */
  --slide-col-span: 5;

  /* The calculated width of a slide. */
  --slide-width: calc(
    var(--slide-column-width) * var(--slide-col-span) +
      (var(--slide-col-span) - 1) * var(--gap)
  );

  @screen xs {
    --slide-col-span: 4;
  }

  @screen sm {
    --slide-col-span: 3;
  }

  @screen md {
    /* From this viewport on, we use the actual column width of the grid again. */
    --slide-column-width: var(--column-width);
  }
}

.teaser-slider-button {
  /* Makes sure the width of the pagination button container + the aspect ratio */
  /* we set in the template results in the button container being the same size */
  /* as the image of the teasers in the slider. This way the button arrows can */
  /* be aligned vertically with the teaser images. */
  width: calc(var(--column-width) * 4 + 3 * var(--gap));
}

.teaser-slider-list {
  /* On small viewports (mobile, tablet) we use native scroll snap scrolling. */
  @apply flex overflow-x-auto overflow-y-hidden scroll-smooth snap-x snap-mandatory gap-gap;

  @screen md {
    @apply overflow-visible snap-none;
    &.swiper-wrapper {
      @apply flex gap-0;
    }
    &:not(.swiper-wrapper) {
      @apply grid;
    }
  }

  > li {
    @apply shrink-0 snap-start box-content;
    width: var(--slide-width);
    scroll-margin-left: var(--root-container-spacer);
    @screen md {
      @apply w-auto !p-0;
    }

    &:first-child {
      /* Make sure the first slide's left edge is exactly at the start of the grid. */
      padding-left: var(--root-container-spacer);
    }

    &:last-child {
      /* Make sure the last slide's left edge is exactly at the start of the grid. */
      /* We basically calculate it in such a way as if there was an additional invisible slide after that one. */
      padding-right: calc(
        100vw - var(--slide-width) - var(--root-container-spacer)
      );
    }
  }
}
</style>
