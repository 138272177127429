<template>
  <TeaserHorizontal
    :tagline="series?.eventType?.name"
    :url="link?.path"
    :title="series?.eventLabel"
    :dates="dates"
    :image="series?.image"
    :content="[series?.targetGroups, series?.locations?.map((l) => l.location)]"
    :accessibility="series?.accessibility"
  >
    <p v-if="showBookedOut" class="font-bold md:text-lg-prose">
      {{ $texts('event.booked', 'ausgebucht') }}
    </p>
  </TeaserHorizontal>
</template>

<script lang="ts" setup>
import type { EventTeaserFragment } from '#graphql-operations'

const props = defineProps<{
  dates?: EventTeaserFragment['dates']
  link?: EventTeaserFragment['link']
  registrationState?: EventTeaserFragment['registrationState']
  series?: EventTeaserFragment['series']
}>()

const { showBookedOut } = useEventRegistration(props.registrationState)
</script>
