<template>
  <SizeAwareContainer
    class="my-6 overflow-hidden"
    :class="{
      'size-aware-full-image': options.size === 'xl',
    }"
  >
    <SizeAwareItem
      v-slot="{ imageStyle }"
      :size="options.size"
      :align="options.imageAlignment"
    >
      <DrupalLink :to="link?.uri?.path">
        <MediaImage
          v-blokkli-droppable:field_image
          v-bind="image"
          :image-style="imageStyle"
          :hide-caption="!options.showCaption"
        />
      </DrupalLink>
    </SizeAwareItem>
  </SizeAwareContainer>
</template>

<script lang="ts" setup>
import type { ParagraphImageFragment } from '#graphql-operations'

defineOptions({
  name: 'ParagraphImage',
})

defineProps<{
  image?: ParagraphImageFragment['image']
  link?: ParagraphImageFragment['link']
}>()

const { options } = defineBlokkli({
  bundle: 'image',
  globalOptions: ['showCaption', 'size', 'imageAlignment'],
  editor: {
    editTitle: (el) => {
      const img = el.querySelector('img')
      return img?.alt || img?.title
    },
    getDraggableElement: (el) => el.querySelector('figure'),
    determineVisibleOptions: function (ctx) {
      if (ctx.options.size === 'xl' || ctx.options.size === 'large') {
        return ['size', 'showCaption']
      }
      return ['size', 'showCaption', 'imageAlignment']
    },
  },
})
</script>
