<template>
  <div>
    <MediaImage
      v-bind="media"
      hide-caption
      :image-style="imageStyle"
      class="max-w-32"
    />
    <h2 class="!mt-3 !font-bold !text-base">{{ title }}</h2>
  </div>
</template>

<script setup lang="ts">
import type {
  MediaImageFragment,
  ParagraphTeaserFragment,
} from '#graphql-operations'

const props = defineProps<{
  title?: ParagraphTeaserFragment['title']
  node?: ParagraphTeaserFragment['node']
  image?: MediaImageFragment
  link?: ParagraphTeaserFragment['link']
  text?: ParagraphTeaserFragment['text']
}>()

const media = computed(() => {
  if (props.node?.__typename === 'NodeExhibition') {
    const exhibitionImage = props.node.teaserImage || props.node.image
    if (exhibitionImage) {
      return exhibitionImage
    }
  }
  if (props.image) {
    return props.image
  }
  if (props.node && 'teaserImage' in props.node) {
    return props.node?.teaserImage
  }
  return undefined
})

const title = computed(() => {
  if (props.title) {
    return props.title
  }
  if (props.node) {
    if ('teaserTitle' in props.node && props.node.teaserTitle) {
      return props.node?.teaserTitle
    } else if ('title' in props.node && props.node.title) {
      return props.node.title
    }
  }
  return ''
})

const imageStyle = defineImageStyle({
  type: 'single',
  width: 400,
  height: 400,
})
</script>
