<template>
  <div class="container container-grid size-aware-container">
    <slot />
  </div>
</template>

<style lang="postcss">
.size-aware-full-image {
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
}
</style>
