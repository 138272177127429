<template>
  <NuxtLink class="block" :to="'/' + language">
    <span class="sr-only">
      {{ $texts('accessibility.go_to_homepage', 'Zur Startseite gehen') }}
    </span>
    <LogoMuseumAniversary v-if="isAnimated" />
    <LogoMuseum v-else />
  </NuxtLink>
</template>

<script lang="ts" setup>
const language = useCurrentLanguage()

// Keep the original prop
defineProps<{
  isAnimated?: boolean
}>()
</script>
