<template>
  <SizeAwareContainer
    v-if="videoUrl || isEditing"
    :size="options.size"
    class="mt-section"
    :class="[paragraphClassList]"
  >
    <SizeAwareItem
      v-slot="{ imageStyle }"
      :size="options.size"
      :align="options.imageAlignment"
    >
      <VuepalRemoteVideo v-if="videoUrl" v-slot="{ embedUrl }" :url="videoUrl">
        <figure class="w-full aspect-video">
          <iframe
            v-if="embedUrl && isPlaying"
            :src="embedUrl"
            allow="autoplay; encrypted-media; picture-in-picture"
            allowfullscreen
            class="w-full aspect-video"
          />

          <button
            v-if="!isPlaying"
            class="aspect-video w-full cursor-pointer flex items-center justify-center relative"
            :aria-label="$texts('video.load', 'Video laden')"
            @click.prevent="isPlaying = true"
          >
            <RokkaImage
              v-if="video?.thumbnailCustom"
              :config="imageStyle"
              v-bind="video.thumbnailCustom"
              :hash="video?.thumbnailCustom?.file?.rokka?.hash || ''"
              class="absolute top-0 left-0 size-full object-cover"
            />

            <img
              v-else
              :src="video?.thumbnailOriginal?.entity?.uri?.first?.url"
              class="absolute top-0 left-0 size-full object-cover"
              :alt="videoDescription"
            />

            <SpriteSymbol name="play" class="size-[75px] z-10" />
          </button>

          <Caption
            :caption="videoDescription"
            :copyright="source"
            class="mt-3"
          />
        </figure>
      </VuepalRemoteVideo>
    </SizeAwareItem>
  </SizeAwareContainer>
</template>

<script lang="ts" setup>
import type { ParagraphVideoFragment } from '#graphql-operations'

const props = defineProps<{
  video?: ParagraphVideoFragment['video']
  videoDescription?: ParagraphVideoFragment['videoDescription']
  contextDescription?: ParagraphVideoFragment['contextDescription']
  source?: ParagraphVideoFragment['source']
}>()

const { options, isEditing } = defineBlokkli({
  bundle: 'video',
  globalOptions: ['spacing', 'showInAnchor', 'size', 'imageAlignment'],
  editor: {
    editTitle: (el) => el.querySelector('h2')?.innerText,
    determineVisibleOptions: function (ctx) {
      if (ctx.options.size === 'xl' || ctx.options.size === 'large') {
        return ['spacing', 'showInAnchor', 'size', 'showCaption']
      }
      return [
        'spacing',
        'showInAnchor',
        'size',
        'showCaption',
        'imageAlignment',
      ]
    },
  },
})

const paragraphClassList = computed(() => {
  const classList: string[] = []

  if (options.value.spacing === 'small') {
    classList.push('py-6', 'lg:py-10')
  } else if (options.value.spacing === 'large') {
    classList.push('py-12', 'lg:py-20')
  }
  if (options.value.size === 'xl') {
    classList.push('size-aware-full-video')
  }
  return classList
})

const isPlaying = ref(false)

const videoUrl = computed(() => props.video?.url)
</script>
